//*********************************************************
//        HEADER
//*********************************************************
.header {
    height: auto;
}
//*********************************************************
//        RED MEDIA
//*********************************************************
.media {
    height:100px;
    position: relative;

    float: left;

    padding: 20px 50px 0 0;



    &--link {
        font-size: 20px;
        font-weight: 700;
        color: @light;
        text-transform: uppercase;

        &:hover,
        &:focus,
        &:active {
            color: @light;
        }
    }

    &:after {
        content: '';
        height: 100%;
        width: 1000px;
        position: absolute;
        right: 25px;
        top: 0;
        transform: skew(-27deg, 0);
        z-index: 1;
        background-color: @brand;
    }
    h1 {
        position: relative;
        z-index: 2;
    }
}
//*********************************************************
//        LOGO
//*********************************************************
.logo {
    float: left;
    padding-top: 38px;

    &--img__small {
        display: none;
    }
}
//*********************************************************
//        TAGS MENU
//*********************************************************
.tags-main {
    padding: 35px 0;
    margin: 0;

    float: right;

    transition: all linear 0.5s;


    &--element {
        display: inline-block;
        a {
            padding: 5px 10px;

            display: block;

            font-weight: 600;
            color: black;
            cursor: pointer;

        }

        &.active {
            a {
                color: @brand;
                cursor: pointer;
            }
        }
    }
}
//*********************************************************
//        HAMBURGER // W wolnej chwili przepiszę na lessa
//*********************************************************
.nav-icon {
    width: 30px;
    height: 40px;
    position: absolute;
    top:25px;
    right:20px;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}
.nav-icon span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: @dark;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
.nav-icon span:nth-child(1) {
    top: 0;
}
.nav-icon span:nth-child(2),
.nav-icon span:nth-child(3) {
    top: 10px;
}
.nav-icon span:nth-child(4) {
    top: 20px;
}
.nav-icon.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
}
.nav-icon.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
.nav-icon.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.nav-icon.open span:nth-child(4) {
    top: 10px;
    width: 0%;
    left: 50%;
}


@media (max-width: @screen-md-min) {
    //*********************************************************
    //        RED MEDIA
    //*********************************************************
    .media {
        height: 70px;

        padding: 8px 40px 0 0;

        &--link {
            font-size: 16px;
        }

        &:before {
            right: 115px;
            z-index: -1;
        }
    }
    //*********************************************************
    //        LOGO
    //*********************************************************
    .logo {
        padding-top: 25px;
    }
    //*********************************************************
    //        TAGS MENU
    //*********************************************************
    .tags-main {

        overflow: hidden;
        padding: 0;

        max-height:0;

        float: left;
        width: 100%;

        text-align: center;

        a:focus {
            color: black;
        }

        &.open {
            max-height: 200px;
            padding: 20px 0 12px;
        }

        &--element {
        }
    }
}

@media (max-width: 600px) {

}
// Small logo
@media (max-width: 480px) {
    //*********************************************************
    //        LOGO
    //*********************************************************
    .logo {
        padding-top: 18px;
        &--img {
            display: none;
        }
        &--img__small {
            display: block;
            margin-top: -10px;
        }
    }
}