.popup {
    width: 100%;
    height: 100%;

    top:0;
    bottom: 0;
    left: 0;
    right: 0;

    position: fixed;
    z-index: 50;

    .popup--close {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
    }
}

.popup--video {
    .video-wrapper {
        width: 60%;
        position: absolute;
        padding-bottom: 41.25%; /* 16:9 */
        height: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @media (max-width: @screen-md-min) {
            width: 100%;
        }
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;


    }
}
