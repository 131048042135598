.filters {
    padding: 30px 0;

    background: #EBEBEB;
}

// INPUTS & LABELS
label {
    font-size: 10px;
    text-transform: uppercase;

    position: absolute;
    top: -16px;
    left: 20px;
}
input {
    border: none;
    padding: 8px 5px 8px 20px;
    font-size: 14px;
    display: block;
    margin: 0 auto;
    border-radius: 20px;
    width: 100%;

    &:focus,
    &:active {
        outline: none;
    }

}
select {
    height: 36px;
    padding: 7px 5px 8px 11px;
    overflow: hidden;
    border: 0;
    position: relative;
    width: 100%;
    border-radius: 25px;
}
//*********************************************************
//        INPUT SEARCH
//*********************************************************
.input-search {
    position: relative;

    .filters-search {
        padding-right: 40px;
    }

    &:after {
        content: '';

        width: 13px;
        height:13px;

        position: absolute;
        right: 15px;
        top: 11px;

        background-image: url("../images/front/search-icon.png");
    }
}

//*********************************************************
//        DISPLAY TYPE - LIST OR GRID
//*********************************************************
.display-type {
    background-color: @light;
    border-radius: 20px;
    display: inline-block;
    padding: 1px 10px;

    &--btn {
        color: @thirdGray;
        display: inline-block;
        padding: 10px 5px;

        &.active,
        &:hover {
            color: @brand;
        }
    }
}

//*********************************************************
//        CHOSEN SELECT
//*********************************************************
.chosen-container {
    .chosen-single {
        height: 36px;
        padding: 6px 15px 7px;
        border-radius: 18px;
        box-shadow: none;

        text-transform: uppercase;

        border: 1px solid @light;
        border-bottom:0;

        .transition(border-color ease 0.3s);

    }
    .chosen-drop {
        border: 1px solid @light;
        border-bottom:0;
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;

        box-shadow: none;

        .transition(border-color ease 0.3s);
    }
    .chosen-results {
        padding: 0 0 0 10px;
        .active-result {
            padding: 7px 6px;

            text-transform: uppercase;

            &.result-selected {
                color: @brand;
                background-color: transparent;
            }
            &.highlighted {
                background-color: transparent;
                background-image: none;
            }
            &:hover {
                color: @brand;
            }
        }
    }
    div b {
        background: transparent!important;
    }
    div b:before {
        content: "\f078";
        position:absolute;
        font-family: FontAwesome;
        display: inline-block;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        top: 5px;
        right: 12px;

        .transition(all ease 0.3s);
    }
    &-active {
        &.chosen-with-drop {
            .chosen-single {
                background-image: none;
                box-shadow: none;

                border: 1px solid #d3d3d3;
                border-bottom: 0;
            }
            .chosen-drop {
                border: 1px solid #d3d3d3;
                border-top: 0;
            }
            div b:before {
                transform: rotate(-180deg);
            }
        }

    }
}
