//*********************************************************
//        COOKIE BANNER
//*********************************************************
.cookie-banner {
    padding: 25px 0;
    background-color: @firstGray;

    text-align: center;

    p {
        margin-bottom: 1px;
    }

    &--close {

        display: inline-block;

        margin-left: 45px;

        color: @brand;
        text-transform: uppercase;

    }
    &--x {
        margin-top: -3px;
        margin-right: 6px;
    }

    .link-inherit {
        text-decoration: underline;
    }
}

//*********************************************************
//        NO RESULTS
//*********************************************************
.no-results {
    width: 100%;

    padding: 40px 0;

    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;

    &--icon {
        width: 70px;
        height: 70px;
        display: block;
        margin: 0 auto;
        font-size: 30px;
        background-color: gray;
        color: white;
        opacity: 0.2;
        border-radius: 50%;
        padding-top: 19px;
        margin-bottom: 20px;

    }

}

//*********************************************************
//        PAGINATION
//*********************************************************
.paginate {
    text-align: center;
    background-color: @firstGray;

    float: left;
    width: 100%;

    padding: 30px 0;
    margin: 0;

    &--element {
        display: inline-block;

        text-align: center;
        width: 40px;
        height: 40px;

        a {
            color: #333;
            padding: 10px 5px;
            border-radius: 50%;
            display: block;
            position: relative;
            cursor: pointer;

        }

        &.active, &:hover {
            a {
                background: @light;
                color: @brand;
            }
        }

        &__prev,
        &__next {
            width: auto;
            cursor: pointer;
            a {
                border-radius: 30px;
                padding: 10px 10px;
            }
        }
    }
}

//*********************************************************
//        LOADER
//*********************************************************

.loader {
    width:100%;
    height:100%;

    position: fixed;

    left: 0;
    top: 0;

    z-index: 200;

    background-color: @light;

    transition: visibility 0.3s, opacity 0.3s;
    visibility: hidden; opacity:0;

    .loader--logo {
        width:75px;
        height:98px;

        position: absolute;
        left: ~"calc(50% - 37px)";
        top: ~"calc(50% - 70px)";
        z-index: 201;


        background-image: url("../images/front/loader.png");

        -webkit-animation-name: pulse;
        animation-name: pulse;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }
}

body.loading > .loader,
body.posts-promoted-loading > .loader{
    visibility: visible;
    opacity: 1;
}

.posts {
    .loader {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;

        .loader--logo {
            top: ~"calc(80px)";
        }
    }
}

.posts-loading {
    .posts {
        .loader {
            visibility:visible;
            opacity:1;
        }
    }
}
.posts-loading.posts-promoted-loading {
    .posts {
        .loader {
            visibility:hidden;
            opacity:0;
        }
    }
}

@-webkit-keyframes pulse {
    0% { -webkit-transform: scale(1); }
    50% { -webkit-transform: scale(0.7); }
    100% { -webkit-transform: scale(1); }
}
@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(0.7); }
    100% { transform: scale(1); }
}


@media (max-width: @screen-md-min) {
    //*********************************************************
    //        FILTERS
    //*********************************************************
    .filters {
        .col-sm-6 {
            margin-top: 35px;
        }
    }
}

@media (max-width: @screen-xs-min) {
    .paginate {
        padding: 15px 0;
        &--element {
            width: 30px;
            height: 30px;

            a {

                padding: 5px 5px;

            }
            &__prev,
            &__next {
                width: auto;
            }

        }

    }
}


