//*********************************************************
//        TYPOGRAPHY
//*********************************************************
p {

}
a {
  color: @dark;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: @brand;
    text-decoration: none;
  }
}
h1 {

}
h3 {
  margin: 0;
}

html {
  font-size: 16px;
}