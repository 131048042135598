.footer {
    padding-bottom: 20px;
    float: left;
    width:100%;

    background-color: @firstGray;

    .info {
        display: table;
        width:100%;

    }
    &--logo {
        display: table-cell;
        float: left;

        margin-top: -10px;
        margin-right: 20px;
    }
    &--content {
        width: 100%;
        display: block;

        font-size: 11px;
    }
    &--social {
        list-style: none;
        float: right;

        font-size: 11px;

        li {
            display: inline-block;
            margin-right:15px;
            a {
                text-align: right;
            }
        }
    }
}
@media (max-width: 480px) {
    //*********************************************************
    //        FOOTER
    //*********************************************************
    .footer {

        &--logo {
            margin-bottom: 30px;
            margin-top: 0;
        }
        &--content {

        }
        &--social {
            margin-left: -25px;
            li {
                display: inline-block;
                margin-right:5px;
                a {
                    text-align: right;
                }
            }
        }
    }
}