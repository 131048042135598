//*********************************************************
//        VARIABLES
//*********************************************************
@dark: #000000;
@light: #ffffff;

@brand: #ec0000;

@thirdGray: #5d5d5d;
@secGray: #9e9d9d;
@firstGray: #ebebeb;
//*********************************************************
//        GENERAL ELEMENTS
//*********************************************************
html {

}
body {
  font-family: 'Open Sans', sans-serif;
}

a {
  .transition(color ease 0.3s);
}
a:hover, a:active, a:focus,
button:hover, button:active, button:focus {
  outline: 0;
  outline: none;
}
//*********************************************************
//        HELPER CLASSES
//*********************************************************
.link-inherit {
  color: inherit;
  font-size: inherit;
}
.p-relative {
  position: relative;
}
