.tags {
    margin: 0;
    padding: 0;

    background: @thirdGray;

    text-align: center;

    &-element {
        padding: 20px 15px;

        display: inline-block;

        &.active {
            a {
                border-bottom: 1px solid @light;

                &:hover {
                    border-color: @brand;
                }
            }
        }

        a {
            color: @light;

            &:hover {
                color: @brand;
            }
        }
    }
}
.tags-main {

}